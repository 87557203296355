<template>
	<v-container fluid>
		<v-layout row wrap>
			<v-flex
				sm12
				xs12
				md12
				lg12
			>
				<v-card>
					<v-toolbar color="#0f2e8c" flat>
						<h3 class="white--text">Tags</h3>
						<v-spacer />
						<v-btn color="green" dark @click="boxAdd = true" v-if="loginInfo._perm === 'admin'">Thêm</v-btn>
					</v-toolbar>
					<v-card-title>
						<v-row>
              <v-col
                  cols="auto"
                  v-if="DataMode !== null"
              >
                <v-btn
                  dark
                  color="blue"
                  @click="DataMode = null"
                >
                  Tất cả
                </v-btn>
              </v-col>
              <v-col
                  cols="auto"
                  v-if="DataMode !== 'tags'"
              >
                <v-btn
                    dark
                    color="green"
                    @click="DataMode = 'tags'"
                >
                  Tags
                </v-btn>
              </v-col>
              <v-col
                  cols="auto"
                  v-if="DataMode !== 'brand'"
                  @click="DataMode = 'brand'"
              >
                <v-btn
                    dark
                    color="pink"
                >
                  Nhãn hiệu
                </v-btn>
              </v-col>
              <v-col
                  cols="auto"
                  v-if="DataMode !== 'top'"
                  @click="DataMode = 'top'"
              >
                <v-btn
                    dark
                    color="orange"
                >
                  Tags Chung
                </v-btn>
              </v-col>
              <v-col
                  cols="auto"
              >
                <v-file-input
                    ref="uploader"
                    v-model="file"
                    accept="image/*"
                    truncate-length="15"
                    label="Tải lên hình ảnh"
                    class="hide"
                    :loading="uploadState"
                    @change="doUploadFiles"
                />
              </v-col>
							<v-spacer></v-spacer>
							<v-col
								cols="12"
								md="6"
								lg="4"
							>
								<v-text-field
									v-model="Search"
									append-icon="search"
									label="Từ khóa 🔎"
									single-line
									hide-details
								/>
							</v-col>
						</v-row>
					</v-card-title>
					<v-card-text>
						<v-data-table
							:loading="loading"
							:headers="headers"
							:items="Items"
							:search="Search"
							loading-text="Đang tải dữ liệu..."
							:footer-props="{itemsPerPageText: 'Dữ liệu mỗi trang','items-per-page-options':[10,20,30,40,50]}"
							:items-per-page="20"
						>
              <template v-slot:[`item.Top`]="{ item }">
                <v-checkbox
                    v-model="item.Top"
                    label="Tag chung"
                    :disabled="updateTopState === item._id || item.Brand"
                    @change="doChangeTopStatus(item)"
                />
              </template>
							<template v-slot:[`item.vi`]="{ item }">
								<template v-if="Edit.id === item._id">
									<v-text-field
										class="mx-2"
										v-model="Edit.vi"
										label="Tiếng Việt"
										single-line
										hide-details
									/>
								</template>
								<template v-else>
									{{item.vi }}
								</template>
							</template>
              <template v-slot:[`item.Brand`]="{ item }">
                <v-checkbox
                  v-model="item.Brand"
                  label="Nhãn hiệu"
                  :disabled="updateBrandState === item._id || item.Top"
                  @change="doChangeBrandStatus(item)"
                />
              </template>
							<template v-slot:[`item.ja`]="{ item }">
								<template v-if="Edit.id === item._id">
									<v-text-field
										class="mx-2"
										v-model="Edit.ja"
										label="Tiếng Nhật"
										single-line
										hide-details
									/>
								</template>
								<template v-else>
									{{item.ja }}
								</template>
							</template>
              <template v-slot:[`item.image`]="{ item }">
                <template v-if="item.image && item.image.length > 0">
                  <v-img
                      :src="`${statics}/${item.image}`"
                      :lazy-src="`${statics}/${item.image}`"
                      contain
                      class="justify-center align-center row mx-auto ma-auto"
                      height="84"
                      width="84"
                  />
                </template>
                <v-btn
                  small
                  color="blue"
                  @click="doStartUpload(item._id)"
                  dark
                >
                  Tải hình
                </v-btn>
              </template>
							<template v-slot:[`item.service`]="{ item }">
								<v-combobox
									v-model="item.service"
									:items="ListCategory"
									item-value="code"
									item-text="name"
									label="Lựa chọn dịch vụ kích hoạt thẻ"
									multiple
									class="mx-2"
								/>
								<v-btn
									class="mx-2"
									color="green"
									dark
									small
									@click="doChangeService(item._id)"
									:disabled="loaderService"
									:loading="loaderService"
								>
									Thay đổi
								</v-btn>
							</template>
							<template v-slot:[`item._id`]="{ item }">
								<v-tooltip
									bottom
									v-if="Edit.id === null"
								>
									<template v-slot:activator="{ on, attrs }">
										<v-btn
											icon
											color="blue"
											dark
											@click="doEdit(item)"
											v-bind="attrs"
											v-on="on"
										>
											<v-icon>
												mdi-pencil
											</v-icon>
										</v-btn>
									</template>
									<span>Sửa nội dung</span>
								</v-tooltip>
								<template v-if="Edit.id !== null">
									<v-tooltip
										bottom
									>
										<template v-slot:activator="{ on, attrs }">
											<v-btn
												icon
												color="blue"
												class="mx-2"
												dark
												@click="doUpdate()"
												v-bind="attrs"
												v-on="on"
											>
												<v-icon>
													mdi-content-save
												</v-icon>
											</v-btn>
										</template>
										<span>Cập nhật dữ liệu</span>
									</v-tooltip>
									<v-tooltip
										bottom
									>
										<template v-slot:activator="{ on, attrs }">
											<v-btn
												icon
												color="orange"
												class="mx-2"
												dark
												@click="doCancel()"
												v-bind="attrs"
												v-on="on"
											>
												<v-icon>
													mdi-content-save-off
												</v-icon>
											</v-btn>
											</template>
										<span>Hủy lệnh thay đổi dữ liệu</span>
									</v-tooltip>
								</template>
								<v-btn
									icon
									color="red"
									class="mx-2"
									dark
									@click="doDelete(item._id)"
								>
									<v-icon>
										mdi-trash-can
									</v-icon>
								</v-btn>
							</template>
						</v-data-table>
					</v-card-text>
				</v-card>
			</v-flex>
		</v-layout>
		<v-dialog
			v-model="boxAdd"
			max-width="600px"
		>
			<v-card flat>
				<v-card-title>
					Thêm thẻ mới
				</v-card-title>
				<v-card-text>
					<v-text-field
						class="mx-12"
						v-model="Add.vi"
						label="Tiếng Việt"
						single-line
						hide-details
					/>
					<v-text-field
						v-model="Add.ja"
						class="mx-12"
						label="Tiếng Nhật"
						single-line
						hide-details
					/>
          <v-checkbox
            v-model="Add.Brand"
            label="Nhãn hiệu"
            class="mx-12"
            :disabled="Add.Top"
          />
          <v-checkbox
              v-model="Add.Top"
              label="Tag chung"
              class="mx-12"
              :disabled="Add.Brand"
          />
				</v-card-text>
				<v-card-actions>
					<v-btn
						color="green"
						dark
						@click="doCreate()"
					>
						Thêm
					</v-btn>
					<v-spacer />
					<v-btn
						dark
						@click="boxAdd = false"
					>
						Đóng
					</v-btn>
				</v-card-actions>
			</v-card>
			<Loading :status="loader" />
		</v-dialog>
	</v-container>
</template>

<script>
import Global from '@/global.js';
import {mapGetters} from "vuex";
import Query from '@/plugins/query/tags.js';
import { Status } from '@/plugins/helper/dstatus'
import Loading from '@/components/base/Loading.vue'
import CategoryDB from '@/json/category.json';
import {Request} from "@/plugins/helper/fetch";
export default {
	name: "Tags",
	components:{
		Loading,
	},
	data(){
		return {
      DataMode: null,
      updateTopState: null,
      updateBrandState: null,
      uploadID: null,
      file: null,
      uploadState: false,
      test: null,
			loader: false,
			Add: {
				vi: null,
				ja: null,
        Brand: false,
        Top: false,
			},
			Search: null,
			boxAdd: false,
			loading: false,
			loaderService: false,
			headers: [
				{ text: 'Tiếng Việt', value: 'vi', align: 'center',width: "250px",class:"grey lighten-2 black--text",sortable:false },
				{ text: 'Tiếng Nhật', value: 'ja', align: 'center',width: "250px",class:"grey lighten-2 black--text",sortable:false },
        { text: 'Nhãn hiệu', value: 'Brand', align: 'center',width: "100px",class:"grey lighten-2 black--text",sortable:false },
        { text: 'Chung', value: 'Top', align: 'center',width: "100px",class:"grey lighten-2 black--text",sortable:false },
        { text: 'Hình ảnh', value: 'image', align: 'center',width: "200px",class:"grey lighten-2 black--text",sortable:false },
				{ text: 'Dịch vụ', value: 'service', align: 'center',width: "150px",class:"grey lighten-2 black--text",sortable:false },
				{ text: 'Thao tác', value: '_id', align: 'center',width: "100px",class:"grey lighten-2 black--text",sortable:false },
			],
			items: [],
			notice: new Status(this.$swal),
			Edit: {
				id: null,
				vi: null,
				ja:null,
			},
		}
	},
	computed: {
		...mapGetters(['authStatus', 'isAuthenticated', 'loginInfo']),
		Category: () => CategoryDB,
    statics(){
      return `${Global.staticTOP}/tags`;
    },
		ListCategory: () => Object.keys(CategoryDB).map(key => CategoryDB[key]),
		Items: function(){
			return this.items.map(item => ({
				...item,
				service: item.service.map(item=>CategoryDB[item]).filter(f=>!!f)
			}))
		},
    Query: () => new Request('tags'),
	},
  watch:{
    DataMode(){
      return this.doDownload();
    }
  },
	methods:{
		...Query,
    doStartUpload(id){
      this.uploadID = id;
      this.$refs.uploader.$refs.input.click();
    },
    doUploadFiles(){
      if(this.file){
        this.uploadState = true;
        let Tasks = [];
        Tasks.push(this.Query.UploadFile(`/upload/${this.uploadID}`,this.file));
        return Promise.all(Tasks).then(this.doFetch).finally(()=>{
          this.uploadState = false;
          this.file = null;
        });
      }else{
        this.Notice.error('Vui lòng chọn file để tải lên !!!');
      }
    },
		doCreate(){
			const { vi, ja, Brand, Top } = this.Add;
			this.loader = true;
			this.qrCreate({vi,ja, Brand, Top}).then(resp=>{
				return resp.json();
			}).then(json=>{
				const {success,error} = json;
				if(success){
					this.notice.success("Tạo tag mới thành công");
					this.boxAdd = false;
					this.Add = {
						vi: null,
						ja:null,
            Brand: false,
            Top: false,
					}
					this.doDownload();
				}
				if(error){
					const msg = json.error || 'Có lỗi khi tạo tag !!!';
					this.notice.error(msg);
				}
			})
				.catch(e=>{
					console.error(e);
					const msg = e.message || 'Lỗi hệ thống !!!';
					this.notice.error("Lỗi hệ thống: "+msg);
				})
				.finally(()=>{
					this.loader = false;
				})
		},
		doEdit(item){
			this.Edit = {
				id: item._id,
				vi: item.vi,
				ja: item.ja,
			}
		},
		doCancel(){
			this.Edit = {
				id: null,
				vi: null,
				ja:null,
			}
		},
		doUpdate(){
			const { id, vi, ja } = this.Edit;
			if(id === null){
				this.notice.error("Không tìm thấy dữ liệu");
				return;
			}
			this.qrUpdate(id, {vi,ja}).then(resp=>{
				return resp.json();
			}).then(json=>{
				const {success, error} = json;
				if(success){
					this.notice.success("Cập nhật thành công");
					this.Edit = {
						id: null,
						vi: null,
						ja:null,
					}
					this.doDownload();
				}
				if(error){
					const msg = json.error || 'Có lỗi khi cập nhật !!!';
					this.notice.error(msg);
				}
			}).catch(e=>{
				console.error(e);
				const msg = e.message || 'Lỗi hệ thống !!!';
				this.notice.error("Lỗi hệ thống: "+msg);
			})
		},
		doDelete(item){
			const app = this;
			app.loading = true;
			this.notice.confirm('Xác nhận ?','Hành động này sẽ thay đổi dữ liệu và không thể phục hồi. Bạn có xác nhận ?').then(success=> {
				if (success) {
					app.qrDelete(item).then(response => {
						const {error} = response;
						if (error) {
							app.notice.error(error);
							return;
						}
						app.notice.success('Đã xóa thành công !!!');
						app.doDownload();
					})
						.finally(() => {
							app.loading = false;
						})
				} else {
					app.loading = false;
				}
			});
		},
		doDownload(){
			this.loading = true;
			this.qrList(this.DataMode).then(resp=>{
				return resp.json();
			}).then(json=>{
				const {data} = json;
				if(data){
					this.items = data;
				}
			}).catch(e=>{
				console.error(e);
				this.notice.error(e);
			}).finally(()=>{
				this.loading = false;
			});
		},
		doChangeService(id){
			const updateTarget = this.Items.filter(f=>f._id === id);
			if(updateTarget.length === 0){
				this.notice.error("Không tìm thấy dữ liệu");
				return;
			}
			let {_id:updateId, service} = updateTarget[0];
			service = service.filter(f=>!!f === true).map(item=>item.code ? item.code: item);
			this.loaderService = true;
			this.qrUpdate(updateId, {service}).then(resp=>{
				return resp.json();
			}).then(json=>{
				const {success, error} = json;
				if(success){
					this.notice.success("Cập nhật thành công");
					this.doDownload();
				}
				if(error){
					const msg = json.error || 'Có lỗi khi cập nhật !!!';
					this.notice.error(msg);
				}
			}).catch(e=>{
				console.error(e);
				const msg = e.message || 'Lỗi hệ thống !!!';
				this.notice.error("Lỗi hệ thống: "+msg);
			}).finally(()=>{
				this.loaderService = false;
			})
		},
    doChangeBrandStatus(item){
      const {_id:updateId, Brand} = item;
      this.updateBrandState = updateId;
      this.qrUpdate(updateId, {Brand}).then(resp=>{
        return resp.json();
      }).then(json=>{
        const {success, error} = json;
        if(success){
          this.notice.success("Cập nhật thành công");
          this.doDownload();
        }
        if(error){
          const msg = json.error || 'Có lỗi khi cập nhật !!!';
          this.notice.error(msg);
        }
      }).catch(e=>{
        console.error(e);
        const msg = e.message || 'Lỗi hệ thống !!!';
        this.notice.error("Lỗi hệ thống: "+msg);
      }).finally(()=>{
        this.updateBrandState = null;
      })
    },
    doChangeTopStatus(item){
      const {_id:updateId, Top} = item;
      this.updateTopState = updateId;
      this.qrUpdate(updateId, {Top}).then(resp=>{
        return resp.json();
      }).then(json=>{
        const {success, error} = json;
        if(success){
          this.notice.success("Cập nhật thành công");
          this.doDownload();
        }
        if(error){
          const msg = json.error || 'Có lỗi khi cập nhật !!!';
          this.notice.error(msg);
        }
      }).catch(e=>{
        console.error(e);
        const msg = e.message || 'Lỗi hệ thống !!!';
        this.notice.error("Lỗi hệ thống: "+msg);
      }).finally(()=>{
        this.updateTopState = null;
      })
    }
	},
	mounted(){
		if(this.loginInfo._perm !== 'admin'){
			this.$router.push('/');
			return;
		}
		this.doDownload();
	}
}
</script>

<style scoped>
.hide {
  visibility: hidden !important;
}
</style>