import { Request } from '@/plugins/helper/fetch'
const req = new Request('tags');
const qrList = (DataMode) => req.fetch(`list${DataMode ? `?mode=${DataMode}`:''}`);
const qrDelete = (id) => req.delete(`${id}`);
const qrCreate = ({vi, ja, Brand, Top}) => req.put(`create`,{vi, ja, Brand, Top});
const qrUpdate = (id, {vi, ja, service, Brand, Top}) => req.patch(`update/${id}`,{vi, ja, service, Brand, Top});
export default {
  qrList,
  qrDelete,
  qrUpdate,
  qrCreate,
}